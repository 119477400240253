import React from 'react'

export default function FormInput({ register, label, type, placeholder, name, errors }) {
  let validator = {}

  if (name === 'email') {
    validator = {
      required: "Required field",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Wrong email format"
      }
    }
  } else {
    validator = {
      required: "Required field",
    }
  }

  if (type === 'textarea') {

    return (
      <>
        <label className="cs-primary_color">{label}</label>
        <span
        className={`${!errors[name]?.message ? "cs-form_error" : "cs-form_error show"}`}
        >{errors[name]?.message}</span>
        <textarea
          cols="30"
          rows="7"
          name={name}
          className={`${!errors[name]?.message ? "cs-form_field" : "cs-form_field error"}`}
          {...register(name, validator)}
        ></textarea>
      </>
    )
  } else {

    return (
      <>
        <label className="cs-primary_color">{label}</label>
        <span
        className={`${!errors[name]?.message ? "cs-form_error" : "cs-form_error show"}`}
        >{errors[name]?.message}</span>
        <input
          type={type}
          className={`${!errors[name]?.message ? "cs-form_field" : "cs-form_field error"}`}
          placeholder={placeholder}
          name={name}
          {...register(name, validator)}
        />
      </>
    )
  }
}