import React from 'react'
import { useForm } from "react-hook-form";
import Div from '../Div'
import Spacing from '../Spacing';
import FormInput from '../FormInput';
import { Icon } from '@iconify/react';
import { Store } from 'react-notifications-component';

export default function FormContact() {
  const { handleSubmit, register, formState: { errors }, reset } = useForm()

  const onSubmit = handleSubmit(data => {
    const formData = new FormData();

    for ( var key in data ) {
      formData.append(key, data[key]);
    }

    fetch("/contact_me.php", {
      method: "POST",
      body: formData,
    }).then(res => {
      console.log(66, res);
      if (res.ok) {
        reset();

        Store.addNotification({
          title: "Success!",
          message: "Message have been sent.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        Store.addNotification({
          title: "Error!",
          message: "Message have not been sent.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    })

  })

  return (
    <form onSubmit={e => e.preventDefault()}
      noValidate action="/contact_me.php" method="POST" className="row">
      <Div className="col-sm-6 relative">
        <FormInput className="weqwe" register={register} errors={errors} label="Full Name*" type="text" name="name" placeholder=""/>
        <Spacing lg="20" md="20" />
      </Div>
      <Div className="col-sm-6 relative">
        <FormInput register={register} errors={errors} label="Email*" type="text" name="email" placeholder=""/>
        <Spacing lg="20" md="20" />
      </Div>
      <Div className="col-sm-12 relative">
        <FormInput register={register} errors={errors} label="Message*" type="textarea" name="message" placeholder=""/>
        <Spacing lg="25" md="25" />
      </Div>
      <Div className="col-sm-12">
        <button onClick={onSubmit} type="submit" className="cs-btn cs-style1">
          <span>Send Message</span>
          <Icon icon="bi:arrow-right" />
        </button>
      </Div>
    </form>
  )
}